import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTestimonials(_, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/testimonialList', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTestimonial(_, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/testimonialById', { params: { id }})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBusinessLine() {
      return new Promise((resolve, reject) => {
        axios
          .get('api/businessLineAll')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTestimonial(_, testimonial) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/testimonialCreate', testimonial)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTestimonial(_, testimonial) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/testimonialUpdate', testimonial)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTestimonial(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/testimonialDelete', { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    swapPosition(_, { oldPosition, newPosition}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/movePosition/homeTestimonial', { old: oldPosition, new: newPosition })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImage(_, images) {
      return new Promise((resolve, reject) => {
        axios
          .post('/uploader', images, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
